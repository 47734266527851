import React from 'react'

const timeSinceDate = date => {
    const dateDiff = new Date() - new Date(date)
    const diffToTime = dateDiff / (1000 * 60 * 60 * 24) / 365
    const totalYears = diffToTime.toString().split('.')[0]
    const totalMonths = Math.floor((diffToTime - totalYears) * 12)

    const yearsText = totalYears > 0 ? `${totalYears} yr${totalYears > 1 ? 's' : ''}` : ''
    const spacer = totalYears > 0 ? ' ' : ''
    const monthsText =  totalMonths > 0 ? `${totalMonths} mo${totalMonths > 1 ? 's' : ''}` : '1 mo'

    return `${yearsText}${spacer}${monthsText}`
}

export default () => (
  <section id="employment">
    <h2>Employment</h2>

    <h3 className="multiple-positions">USAA</h3>
    <p className="total-years">{timeSinceDate('2017/09/05')}</p>
    <ul>
      <li>
        <span className="role">Lead Software Engineer </span><br />
        <span className="tenure">
          Mar 2020 &mdash; Present &#8226; {timeSinceDate('2020/03/29')}
        </span>
      </li>
      <li>
      <span className="role">Senior Software Engineer</span> <br />
        <span className="tenure">
          Dec 2018 &mdash; Mar 2020 &#8226; 1 yr 4 mos
        </span>
      </li>
      <li>
        <span className="role">Software Engineer</span> <br />
        <span className="tenure">
          Sep 2017 &mdash; Dec 2018 &#8226; 1 yr 4 mos
        </span>
      </li>
    </ul>
    <div className="job-description">
      <p>Portfolio System Architect responsible for architectural decisions and artifacts to facilitate business objectives. Provide technical guidance to over 80 developers across 12 execution teams including design, implementation, and maintenance.</p>
      <p>Cultural and process champion as a founding team member of a Tech Transformation initiative to promote adoption of industry standard practices and infrastructure.</p>
      <p>Experienced Tech Lead, subject matter expert on multiple applications, responsible for feature delivery, ensuring best practices and mentoring colleagues.</p>
      <p>Developed responsive web applications using React, Redux, Jest, Selenium, and Cypress. Maintained mock servers, custom React components, alerting and monitoring, and ensured accessibility standards were upheld.</p>
      <p>Saved development and testing time enterprise-wide by creating an internal Chrome extension to automate test user authentication and environment routing.</p>
    </div>

    <h3 className="multiple-positions">Envestnet</h3>
    <p className="total-years">3 yrs</p>
    <ul>
      <li>
        <span className="role">Senior Software Engineer</span> <br />
        <span className="tenure">
          Jan 2017 &mdash; Sep 2017 &#8226; 9 mos
        </span>
      </li>
      <li>
        <span className="role">Software Engineer</span> <br />
        <span className="tenure">
            Oct 2014 &mdash; Dec 2016 &#8226; 2 yrs 4 mos
        </span>
      </li>
    </ul>
    <div className="job-description">
      <p>Principal developer in Placemark's acquisition to integrate their industry-leading technology into Envestnet's platform through an AngularJS application.</p>
      <p>Tech Lead for modernizing Envestnet's trading platform in React.</p>
    </div>

    <h3 className="multiple-positions">Placemark Investments</h3>
    <span className="disclosure">
      Placemark Investments was acquired by Envestnet
    </span>
    <ul>
      <li>
        <span className="role">Software Engineer</span> <br />
        <span className="tenure">
          Jul 2013 &mdash; Sep 2014 &#8226; 1 yr 4 mos
        </span>
      </li>
    </ul>
    <div className="job-description">
      <p>Developed proprietary web applications for financial advisors and led team with best practices in UI standards.</p>
      <p>Developed internal trading applications for money managers with Tcl/Tk.</p>
      <p>Proactively sought opportunities to innovate by creating a Pivotal Tracker API wrapper in Python that automated project management workflows.</p>
      <p>Spearheaded a custom dashboard to monitor and display server health from Nagios' API supporting internal infrastructure teams.</p>
    </div>

    <h3>Vertical Nerve</h3>
    <ul>
      <li>
        <span className="role">Jr. Web Developer</span> <br />
        <span className="tenure">
          Sep 2012 &mdash; Jun 2013 &#8226; 10 mos
        </span>
      </li>
    </ul>
    <div className="job-description">
      <p>Developed responsive websites with a proprietary PHP Zend Framework CMS.</p>
      <p>Lead transition from proprietary PHP CMS to Drupal.</p>
      <p>Monitored support system and resolved support tickets for production websites.</p>
      <p>Developed dashboards rendering live data from Google Analytics and Facebook.</p>
    </div>

    <h3>University of North Texas System</h3>
    <ul>
      <li>
        <span className="role">Web Developer</span> <br />
        <span className="tenure">
          Apr 2012 &mdash; Sep 2012 &#8226; 6 mos
        </span>
      </li>
    </ul>
    <div className="job-description">
      <p>Developed UNT's Information Technology Shared Services (ITSS) website in Drupal 7.</p>
      <p>Managed roles and access at a highly granulated level utilizing Workbench Access and implemented a workflow for posting and approving content.</p>
      <p>Extended UNT's Drupal theme and modified for ITSS branding.</p>
      <p>Performed Drupal training for UNT employees.</p>
      <p>Migrated websites and data from Drupal 6 to Drupal 7 for ITSS departments.</p>
    </div>

    <h3>Roobix, LLC</h3>
    <ul>
      <li>
        <span className="role">Web Developer (Remote Contractor)</span> <br />
        <span className="tenure">
          Dec 2010 &mdash; Sep 2012 &#8226; 1 yr 10 mos
        </span>
      </li>
    </ul>
    <div className="job-description">
      <p>Contract to develop features for Service King website.</p>
      <p>Developed a user rating workflow process for users.</p>
      <p>Developed custom HTML, CSS, PHP, and jQuery.</p>
      <p>Constructed and parsed SQL, XML, RSS.</p>
      <p>Utilized SOAP protocol in PHP for web services.</p>
      <p>Integrated Facebook API allows users to share a review.</p>
    </div>

    <h3>University of North Texas</h3>
    <ul>
      <li>
        <span className="role">Web Developer</span> <br />
        <span className="tenure">
          Feb 2008 &mdash; Apr 2012 &#8226; 4 yrs 3 mos
        </span>
      </li>
    </ul>
    <div className="job-description">
      <p>Maintained sixteen UNT department websites under the Office of the Provost.</p>
      <p>Developed custom HTML, JavaScript, CSS, and graphics.</p>
      <p>Migrated websites to Drupal for efficient management of content and users.</p>
      <p>Performed Drupal training for department managers to empower them to customize and maintain their content.</p>
    </div>
  </section>
);
